import { swapxBlockClient } from '../apollo/swapx_client'
import { GET_SWAPX_BLOCKS } from '../apollo/swapx_queries'
import { splitQuery } from './analyticsHelper'

export async function getSwapxBlocksFromTimestamps(timestamps, skipCount = 500) {
  if (timestamps?.length === 0) {
    return []
  }

  const fetchedData = await splitQuery(GET_SWAPX_BLOCKS, swapxBlockClient, [], timestamps, skipCount)

  const blocks = []
  if (fetchedData) {
    for (const t in fetchedData) {
      if (fetchedData[t].length > 0) {
        blocks.push({
          timestamp: t.split('t')[1],
          number: Number(fetchedData[t][0]['number']),
        })
      }
    }
  }

  return blocks
}
