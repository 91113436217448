import React, { useMemo, useState } from 'react'
import { useColorMode, defineStyle, Button } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router'
import dayjs from 'dayjs'

import TransictionTable from '../../../components/pages/analytics/transictionTable'
import AnalyticsHeader from '../../../components/pages/analytics/analyticsHeader'
import { usePairChartData, usePairData, usePairTransactions } from '../../../context/PairData'
import { useLogoFromAddress } from '../../../hooks/v3/useCurrencyLogo'
import { formatAmount } from '../../../utils/formatNumber'
import { getChartDates, getYAXISValuesAnalytics } from '../../../utils/analyticsHelper'
import AreaChart from '../../../components/common/AreaChart'
import { getScanUrl } from '../../../utils'
import { cn } from '../../../helpers/tailwind'
import LeftPlayArrowIcon from '../../../components/icons/left-play-arrow'
import LinkIcon from '../../../components/icons/link'
import { AnalyticChart, timeframeOptions, TXN_TYPE } from '../../../config/constants'
import TimeframeFilter from '../../../components/common/TimeframeFilter'

// const ChartIndexes = {
//   Volume: 'Volume',
//   TVL: 'TVL',
//   Fees: 'Fees',
// }

const leftPlayArrowIconStyle = defineStyle({
  width: '16px',
  height: 'auto',
})

const linkIconStyle = defineStyle({
  width: '16px',
  height: 'auto',
})

const PairDetails = () => {
  // const [chartIndex, setChartIndex] = useState(ChartIndexes.Volume)
  const { id: pairAddress } = useParams()
  const [filter, setFilter] = useState(TXN_TYPE.ALL)
  const navigate = useNavigate()
  const { colorMode } = useColorMode()
  const pairData = usePairData(pairAddress)
  const { token0, token1 } = pairData
  const transactions = usePairTransactions(pairAddress, pairData)
  const pairChartData = usePairChartData(pairAddress, pairData)
  const token0Logo = useLogoFromAddress(token0?.id)
  const token1Logo = useLogoFromAddress(token1?.id)

  const [tvlTimeType, setTvlTimeType] = useState(timeframeOptions['WEEK'])
  const [volumeTimeType, setVolumeTimeType] = useState(timeframeOptions['WEEK'])
  const [feesTimeType, setFeesTimeType] = useState(timeframeOptions['WEEK'])

  const [tvlData, tvlDates, tvlCategories, tvlDuration, tvlUSD, tvlTimeLabel] = useMemo(() => {
    if (pairChartData && Object.keys(pairData).length > 0) {
      let _data
      if (tvlTimeType === timeframeOptions['ALL_TIME']) {
        _data = pairChartData.weekly
        return [
          _data.map((i) => i.weeklyTvlUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.SIX_MONTH_CHART,
          pairData.totalValueLockedUSD,
          dayjs().format('MMM DD, YYYY'),
        ]
      } else if (tvlTimeType === timeframeOptions['MONTH']) {
        _data = pairChartData.daily?.slice(-31) || []
        return [_data.map((i) => i.reserveUSD), _data.map((i) => i.date), _data, AnalyticChart.ONE_MONTH_CHART, pairData.tvlOneMonthUSD, 'Past Month']
      } else {
        _data = pairChartData.daily?.slice(-7) || []
        return [_data.map((i) => i.reserveUSD), _data.map((i) => i.date), _data, AnalyticChart.ONE_WEEK_CHART, pairData.tvlOneWeekUSD, 'Past Week']
      }
    }
    return [null, null, null, 0, 0, '']
  }, [tvlTimeType, pairChartData, pairData])

  const [volumeData, volumeDates, volumeCategories, volumeDuration, volumeUSD, volumeTimeLabel] = useMemo(() => {
    if (pairChartData) {
      let _data
      if (volumeTimeType === timeframeOptions['ALL_TIME']) {
        _data = pairChartData.weekly
        return [
          _data.map((i) => i.weeklyVolumeUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.SIX_MONTH_CHART,
          pairData.oneDayVolumeUSD,
          dayjs().format('MMM DD, YYYY'),
        ]
      } else if (volumeTimeType === timeframeOptions['MONTH']) {
        _data = pairChartData.daily?.slice(-31) || []
        return [_data.map((i) => i.dailyVolumeUSD), _data.map((i) => i.date), _data, AnalyticChart.ONE_MONTH_CHART, pairData.oneMonthVolumeUSD, 'Past Month']
      } else {
        _data = pairChartData.daily?.slice(-7) || []
        return [_data.map((i) => i.dailyVolumeUSD), _data.map((i) => i.date), _data, AnalyticChart.ONE_WEEK_CHART, pairData.oneWeekVolumeUSD, 'Past Week']
      }
    }
    return [null, null, null, 0, 0, '']
  }, [volumeTimeType, pairChartData, pairData])

  const [feesData, feesDates, feesCategories, feesDuration, feesUSD, feesTimeLabel] = useMemo(() => {
    if (pairChartData) {
      let _data
      if (feesTimeType === timeframeOptions['ALL_TIME']) {
        _data = pairChartData.weekly
        return [
          _data.map((i) => i.weeklyFeesUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.SIX_MONTH_CHART,
          pairData.oneDayFeesUSD,
          dayjs().format('MMM DD, YYYY'),
        ]
      } else if (feesTimeType === timeframeOptions['MONTH']) {
        _data = pairChartData.daily?.slice(-31) || []
        return [_data.map((i) => i.feesUSD), _data.map((i) => i.date), _data, AnalyticChart.ONE_MONTH_CHART, pairData.oneMonthFeesUSD, 'Past Month']
      } else {
        _data = pairChartData.daily?.slice(-7) || []
        return [_data.map((i) => i.feesUSD), _data.map((i) => i.date), _data, AnalyticChart.ONE_WEEK_CHART, pairData.oneWeekFeesUSD, 'Past Week']
      }
    }
    return [null, null, null, 0, 0, '']
  }, [feesTimeType, pairChartData, pairData])

  return (
    <div className='w-full'>
      <div className='max-w-[1104px] mx-auto w-full'>
        <AnalyticsHeader data={pairData} />
        {Object.keys(pairData).length > 0 && (
          <>
            <div className='lg:flex items-center justify-between mt-7 md:mt-9'>
              <div>
                <div className='sm:flex sm:items-center sm:space-x-5'>
                  <button
                    className={cn(
                      'w-[45px] h-[45px] rounded-[50%] text-[#EAECE8] flex items-center justify-center transition-all duration-300 ',
                      colorMode === 'dark' ? 'bg-background border-[1px] border-background hover:border-[#7AF182]' : 'bg-primaryText hover:bg-grayText',
                    )}
                    onClick={() => navigate('/analytics/pairs')}
                  >
                    <LeftPlayArrowIcon {...leftPlayArrowIconStyle} />
                  </button>
                  <div className='flex items-center space-x-2 sm:mt-0 mt-3'>
                    <div className='flex items-center -space-x-4'>
                      <img alt='' src={token0Logo} className='w-[60px] z-10' />
                      <img alt='' src={token1Logo} className='w-[60px]' />
                    </div>
                    <div>
                      <div className='text-[23px] md:text-[27px] leading-8 font-semibold'>
                        <span
                          className='cursor-pointer'
                          onClick={() => {
                            navigate(`/analytics/total/token/${token0.id}`)
                          }}
                        >
                          {token0.symbol}
                        </span>
                        /
                        <span
                          className='cursor-pointer'
                          onClick={() => {
                            navigate(`/analytics/total/token/${token1.id}`)
                          }}
                        >
                          {token1.symbol}
                        </span>
                      </div>
                      {pairData.fee && (
                        <div className={`text-[15px] table font-medium mt-1`}>
                          <span className='text-[#727272]'>Fee:</span>
                          <span className='text-primaryText'> {Number(pairData.fee) / 10000}%</span>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-col items-end sm:justify-end justify-between h-full'>
                      <div
                        className={cn(
                          'cursor-pointer text-primaryText transition-all duration-300 lg:hidden block lg:mb-0 mb-1 ',
                          colorMode === 'dark' ? 'hover:text-[#7AF182]' : 'hover:text-grayText',
                        )}
                        onClick={() => window.open(getScanUrl() + 'address/' + pairData.id, '_blank')}
                      >
                        <LinkIcon {...linkIconStyle} />
                      </div>
                      <div
                        className={cn('py-[3px] px-[12px] rounded-[15px] text-[#EAECE8] text-sm ', colorMode === 'dark' ? 'bg-background' : 'bg-primaryText')}
                      >
                        {'5'} Pools
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-center space-x-3 md:space-x-5 mt-3 lg:mt-0'>
                <div
                  className={cn(
                    'cursor-pointer text-primaryText transition-all duration-300 lg:block hidden mr-3 ',
                    colorMode === 'dark' ? 'hover:text-[#7AF182]' : 'hover:text-grayText',
                  )}
                  onClick={() => window.open(getScanUrl() + 'address/' + pairData.id, '_blank')}
                >
                  <LinkIcon {...linkIconStyle} />
                </div>
                <Button
                  size='action'
                  padding='10px 20px'
                  height='auto'
                  className='sm:w-full !w-auto !block min-w-[120px] !ml-0'
                  onClick={() => {
                    if (pairData.isFusion) {
                      navigate(`/liquidity/fusion?currency0=${token0.id}&currency1=${token1.id}`)
                    } else {
                      navigate(`/liquidity/manage/${pairData.id}`)
                    }
                  }}
                >
                  <div>ADD LIQUIDITY</div>
                </Button>
                <Button
                  size='action'
                  padding='10px 20px'
                  height='auto'
                  className='sm:w-full !w-auto !block min-w-[120px]'
                  onClick={() => {
                    navigate(`/swap`)
                  }}
                >
                  <div>SWAP</div>
                </Button>
              </div>
            </div>
            <div className='lg:flex lg:space-x-6 space-y-[26px] lg:space-y-0 mt-5 lg:mt-6'>
              <div className={cn('bg-background rounded-[15px] w-full lg:w-1/2 ', colorMode === 'dark' ? '' : 'card-shadow')}>
                <div className='px-6 py-[22px] h-full'>
                  <div className='sm:flex inline-flex sm:justify-between sm:items-center gap-x-3 gap-y-1 sm:flex-row flex-col'>
                    <div className='flex items-center gap-2'>
                      <img alt='' src={token0Logo} className='w-[36px] h-auto' />
                      <p className='text-[23px] font-medium'>{formatAmount(pairData.reserve0)}</p>
                    </div>
                    <div
                      className={cn(
                        'py-[4px] px-[12px] rounded-[15px] text-[#EAECE8] text-sm w-auto ',
                        colorMode === 'dark' ? 'bg-[#1C211F]' : 'bg-primaryText',
                      )}
                    >
                      1 {token0.symbol} = {formatAmount(Number(token0.derivedETH || token0.derivedEth) / Number(token1.derivedETH || token1.derivedEth))}{' '}
                      {token1.symbol}
                    </div>
                  </div>
                  <div className='mt-1'>Total {token0.symbol} Locked</div>
                </div>
              </div>
              <div className={cn('bg-background rounded-[15px] w-full lg:w-1/2 ', colorMode === 'dark' ? '' : 'card-shadow')}>
                <div className='px-6 py-[22px] h-full'>
                  <div className='sm:flex inline-flex sm:justify-between sm:items-center gap-x-3 gap-y-1 sm:flex-row flex-col'>
                    <div className='flex items-center gap-2'>
                      <img alt='' src={token1Logo} className='w-[36px] h-auto' />
                      <p className='text-[23px] font-medium'>{formatAmount(pairData.reserve1)}</p>
                    </div>
                    <div
                      className={cn(
                        'py-[4px] px-[12px] rounded-[15px] text-[#EAECE8] text-sm w-auto ',
                        colorMode === 'dark' ? 'bg-[#1C211F]' : 'bg-primaryText',
                      )}
                    >
                      1 {token1.symbol} = {formatAmount(Number(token1.derivedETH || token1.derivedEth) / Number(token0.derivedETH || token0.derivedEth))}{' '}
                      {token0.symbol}
                    </div>
                  </div>
                  <div className='mt-1'>Total {token1.symbol} Locked</div>
                </div>
              </div>
            </div>
            <div className={cn('bg-background rounded-[15px] w-full mt-5 lg:mt-6', colorMode === 'dark' ? '' : 'card-shadow')}>
              <div className='px-6 py-[22px] h-full'>
                <div className='flex items-center justify-between gap-2'>
                  <div>TVL</div>
                  <TimeframeFilter filter={tvlTimeType} setFilter={setTvlTimeType} />
                </div>
                <div>
                  <div className='flex items-center space-x-3'>
                    <div className='font-bold text-[20px] lg:text-[27px]'>${formatAmount(tvlUSD)}</div>
                  </div>
                  <div className='text-[10px] lg:text-[15px]'>{tvlTimeLabel}</div>
                </div>
                {tvlData && (
                  <AreaChart
                    data={tvlData}
                    yAxisValues={getYAXISValuesAnalytics(tvlData)}
                    dates={tvlDates}
                    height={275}
                    categories={getChartDates(tvlCategories, tvlDuration)}
                  />
                )}
              </div>
            </div>
            <div className='lg:flex lg:space-x-6 space-y-[26px] lg:space-y-0 mt-5 lg:mt-6'>
              <div className={cn('bg-background rounded-[15px] w-full lg:w-1/2 ', colorMode === 'dark' ? '' : 'card-shadow')}>
                <div className='px-6 py-[22px] h-full'>
                  <div className='flex items-center justify-between gap-2'>
                    <div>Volume</div>
                    <TimeframeFilter filter={volumeTimeType} setFilter={setVolumeTimeType} />
                  </div>
                  <div>
                    <div className='flex items-center space-x-3'>
                      <div className='font-bold text-[20px] lg:text-[27px]'>${formatAmount(volumeUSD)}</div>
                    </div>
                    <div className='text-[10px] lg:text-[15px]'>{volumeTimeLabel}</div>
                  </div>
                  {volumeData && (
                    <AreaChart
                      data={volumeData}
                      yAxisValues={getYAXISValuesAnalytics(volumeData)}
                      dates={volumeDates}
                      height={275}
                      categories={getChartDates(volumeCategories, volumeDuration)}
                      type='bar'
                      strokeColor={colorMode === 'dark' ? '#7af18280' : '#7AF182'}
                    />
                  )}
                </div>
              </div>
              <div className={cn('bg-background rounded-[15px] w-full lg:w-1/2 ', colorMode === 'dark' ? '' : 'card-shadow')}>
                <div className='px-6 py-[22px] h-full'>
                  <div className='flex items-center justify-between gap-2'>
                    <div>Fees</div>
                    <TimeframeFilter filter={feesTimeType} setFilter={setFeesTimeType} />
                  </div>
                  <div>
                    <div className='flex items-center space-x-3'>
                      <div className='font-bold text-[20px] lg:text-[27px]'>${formatAmount(feesUSD)}</div>
                    </div>
                    <div className='text-[10px] lg:text-[15px]'>{feesTimeLabel}</div>
                  </div>
                  {feesData && (
                    <AreaChart
                      data={feesData}
                      yAxisValues={getYAXISValuesAnalytics(feesData)}
                      dates={feesDates}
                      height={275}
                      categories={getChartDates(feesCategories, feesDuration)}
                      type='bar'
                      strokeColor={colorMode === 'dark' ? '#7af18280' : '#7AF182'}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <p className='mt-10'>
          <div className={`flex items-center text-lg leading-[22px]  font-normal  space-x-3 w-full`}>
            {Object.values(TXN_TYPE).map((option, idx) => {
              return (
                <button className={`font-medium ${filter === option ? 'text-primaryText' : 'text-grayText'}`} onClick={() => setFilter(option)} key={idx}>
                  {option}
                </button>
              )
            })}
          </div>
        </p>
        <TransictionTable data={transactions} filter={filter} />
      </div>
    </div>
  )
}

export default PairDetails
