//import SLogo from '../../assets/tokens/sonic.png';
import SWPxLogo from '../../assets/tokens/swpx.svg'
import USDTLogo from '../../assets/tokens/usdt.png'
import AurumLogo from '../../assets/tokens/aurum.png'
import SacraLogo from '../../assets/tokens/sacra.png'
import SacraGemLogo from '../../assets/tokens/sacragem.png'
import SCETHLogo from '../../assets/tokens/sceth.png'
import SCUSDLogo from '../../assets/tokens/scusd.png'
import SOLVBTCLogo from '../../assets/tokens/solvBTC.png'
import SOLVBTCBBNLogo from '../../assets/tokens/solvbtcbbn.png'
import STSLogo from '../../assets/tokens/sts.png'
import TYSGLogo from '../../assets/tokens/tysg.png'
import USDCLogo from '../../assets/tokens/usdc.png'
import WETHLogo from '../../assets/tokens/weth.png'
import WOOFLogo from '../../assets/tokens/woof.png'
import USDALogo from '../../assets/tokens/usda.png'
import SUSDALogo from '../../assets/tokens/susda.png'
import AtEthLogo from '../../assets/tokens/ateth.png'
import AtollLogo from '../../assets/tokens/atoll.png'
import SlogoSVG from '../../assets/tokens/s.svg'
import stkscusdLogo from '../../assets/tokens/stkscusd.png'
import shibaLogo from '../../assets/tokens/shiba.png'
import wsLogo from '../../assets/tokens/ws.svg'
import { zeroAddress } from 'viem'

const ADDRESSES = {
  owner: '0x4D5bCfc14282d930eE09bee96D3a7D5F338251C6',
  zero: '0x0000000000000000000000000000000000000000',
  proxyAdmin: '0xEd37Cbf5Df2765C48cEDDf1C5F152563057241c7',
  timelocker: '0x650Abd47800F1e4e8c5487b2cC9dC895fC3c82fD',
  permissionRegistry: '0x8751ea0634f85474c94e8462e93751D2104Ed487',
  gaugeFactoryV2: '0x50EAD88c9F4FA95bda7b21310F26C96402875458',
  gaugeFactoryCL: '0x0326Bc059CbeeEd569f285Fdeb7365ff6369b120',
  bribeFactory: '0x1fB16400652F999B79F62c3a4cEa3Ebc7185b6b3',
  veArt: '0xA194E79ffe35a07D640D2595B8f3e0d788A43846',
  votingEscrow: '0xAA30F0977620D4d46B3Bb3Cf0794Fe645d576CA3',
  rewardDistributor: '0x1C236D74f61C6199e6D23201D53a59753f4c94D3',
  voter: '0xC1AE2779903cfB84CB9DEe5c03EcEAc32dc407F2',
  monolithicVoter: '0xf3bb6075b7e33e0b2c892aacdba2026fd6b20b4d',
  minter: '0x8b8c58CC70D13905EbF46c2a5ed1f41D52c83364',
  swpxnft: '0xc83F364827B9f0d7b27A9c48b2419e4a14E72f78',
  royalties: '0x7ab97fcA4eC80C41106881F56a3a3fE3e9A8b43B',
  feeConverter: '0x4053EC5Dfa4e9B6DE10A02106dc3320E43C07DE0',
  splitter: '0x86FA3c42928927364d3aC5a79989f3056b1345C0',
  masterchefVesting: '0x91cc717c1090bF7c4A90d773cDEc8cD0c421460B',
  vesting1: '0x972E71e6f262E9C51a4F2b05dA2c307134f258Ec',
  vesting2: '0x3a4744c745908AD39A9225449F520A94EB44eE85',
  vesting3: '0x082C47F07a879A1b3680F2295FCe12F41D7f4069',
  masterchef: '0x66F28a0d830e9be7C2Bb2c626d9cdF528A5EACB1',
  airdropClaim1: '0x10E075Bef363bc7018bc723B2fBd7fef4a56D350',
  airdropClaim2: '0x5Df06c77667cca1Db8cAC4c163C9E90066478734',
  airdropClaim3: '0xEafc392feE575A4F8f147b2BFB2B6662873a6E99',
  merkleTree1: '0x36daa584Cb9161F0d1bb301864EC728EC06f6419',
  merkleTree2NFT: '0xFCcBb2A092626BC8625417d22222c82D3648ad59',
  merkleTree3: '0xe4B7bB7e4F7b27f2C1def2C8055b9988dDB55F60',
  claimFeesMulticall: '0xDb3E0332Fa9361ac80bdA96222d9580a5c00b1a9',
  referralDistributor: '0x968Ff4C1ce7d5E5F770810acDC8395b159285E28',
  api: {
    pairAPI: '0x2Ec58A0CAae0e8c27E28833edE5ab7415C31C4FF',
    veNFTAPI: '0xE76C57f200E7057e340dA1C28fDC27E180B6BEc7',
  },
  tokens: {
    wokb: '0x039e2fB66102314Ce7b64Ce5Ce3E5183bc94aD38',
    usdt: '0x9c5FC50155C29E6b0269dE84a1aE40D43B6Fb6D2',
    auusdc: '0xd6a69ebfa44f78cee454a2df2c77751a42f8f38c', // MAINNET // STABLE
    swpx: '0xA04BC7140c26fc9BB1F36B1A604C7A5a88fb0E70',
    usdce: '0x29219dd400f2Bf60E5a23d13Be72B486D4038894', // MAINNET // STABLE
    sceth: '0x3bcE5CB273F0F148010BbEa2470e7b5df84C7812', // MAINNET
    sts: '0xE5DA20F15420aD15DE0fa650600aFc998bbE3955', // MAINNET
    weth: '0x50c42dEAcD8Fc9773493ED674b675bE577f2634b', // MAINNET
    scusd: '0xd3DCe716f3eF535C5Ff8d041c1A41C3bd89b97aE', // MAINNET // STABLE
    stkscusd: '0x4d85ba8c3918359c78ed09581e5bc7578ba932ba', // MAINNET // STABLE
    sacra: '0x7AD5935EA295c4E743e4f2f5B4CDA951f41223c2', // MAINNET
    sacragem: '0xfC0dd337b92Baa949bC5D25FD9A99Cb3b6873204', // MAINNET
    tysg: '0x56192E94434c4fd3278b4Fa53039293fB00DE3DB', // MAINNET
    woof: '0x7F883dA3B0d77978075f7C9c03E1B9F461CA1B8d', // MAINNET
    usda: '0xff12470a969Dd362EB6595FFB44C82c959Fe9ACc', // MAINNET // STABLE
    aurum: '0x7F144F8691CbA3d2EfD8e5bcf042f9303EE31a46', // MAINNET
    solvbtc: '0x541FD749419CA806a8bc7da8ac23D346f2dF8B77', // MAINNET
    solvbtcbbn: '0xCC0966D8418d412c599A6421b760a847eB169A8c', // MAINNET
    hedgy: '0x6fb9897896fe5d05025eb43306675727887d0b7c', // MAINNET
    fiery: '0xf97d438bc03ad0f75b83ce5714c9619880b305bc', // MAINNET
    moon: '0x486b6fa0419b33a0c7a6e4698c231d7e2f2d5299', // MAINNET
    susda: '0x2840F9d9f96321435Ab0f977E7FDBf32EA8b304f', // MAINNET // STABLE
    ateth: '0x284D81e48fBc782Aa9186a03a226690aEA5cBe0E', // MAINNET
    eco: '0x7A08Bf5304094CA4C7b4132Ef62b5EDc4a3478B7', // MAINNET
    sdog: '0x50bc6e1dff8039a4b967c1bf507ba5ea13fa18b6', // MAINNET
    atoll: '0xd1DA314f7db27DbE3c61aC172bE13d86e67688B7', // MAINNET
    goglz: '0x9fdbc3f8abc05fa8f3ad3c17d2f806c1230c4564', // MAINNET
    shiba: '0xd8794eee1bc5a883d42c737e653a237adb6acdcc', // MAINNET
    bol: '0x38628cb4cc74cbdfb6ddd877b17c1f60fcae7c2c', // MAINNET
    os: '0xb1e25689d55734fd3fffc939c4c3eb52dff8a794', // MAINNET
    anon: '0x79bbf4508b1391af3a0f4b30bb5fc4aa9ab0e07c', // MAINNET
  },
  algebraDex: {
    poolInitCodeHash: '0xf96d2474815c32e070cd63233f06af5413efc5dcb430aee4ff18cc29007c562d',
    poolDeployer: '0x885229E48987EA4c68F0aA1bCBff5184198A9188',
    algebraFactory: '0x8121a3F8c4176E9765deEa0B95FA2BDfD3016794',
    pluginFactory: '0x11F0Ccf4aC81878B81EBc907b8B9a9ECd089a227',
    quoter: '0x05270FBbB6db1d8da76bBF70509eFa57971a7Ae5',
    quoter2: '0xd74a9Bd1C98B2CbaB5823107eb2BE9C474bEe09A',
    swapRouter: '0xE6E9F79e551Dd3FAeF8aBe035896fc65A9eEB26c',
    nftPositionManager: '0xd82Fe82244ad01AaD671576202F9b46b76fAdFE2',
    interfaceMulticall: '0xeFc790C82ecA58054A7d17bd27cb1b6Ce3D3A237',
    vaultFactory: '0xdF18a15eaDf50a40d8A1c31EbD9DcCa1edCdE881',
    AlgebraCommunityVault: '0xf0F62ED4F66572faB5ED4513B99e53BB2660f42A',
    SwapRouter02: '0xA047e2AbF8263FcA7c368F43e2f960A06FD9949f',
  },
  thenaDex: {
    pairFactory: '0x05c1be79d3aC21Cc4B727eeD58C9B2fF757F5663',
    routerV2: '0xF5F7231073b3B41c04BA655e1a7438b1a7b29c27',
  },
}

export const SPECIAL_TOKENS = {
  S: {
    symbol: 'S',
    logo: SlogoSVG,
    address: zeroAddress,
    name: 'Sonic',
  },
  wS: {
    symbol: 'wS',
    logo: wsLogo,
    address: ADDRESSES.tokens.wokb,
    name: 'Wrapped Sonic',
  },
  WSonic: {
    symbol: 'wS',
    logo: wsLogo,
    address: ADDRESSES.tokens.wokb,
    name: 'Wrapped Sonic',
  },
  SWPx: {
    symbol: 'SWPx',
    logo: SWPxLogo,
    address: ADDRESSES.tokens.swpx,
    name: 'SwapX',
  },
  USDT: {
    symbol: 'USDT',
    logo: USDTLogo,
    address: ADDRESSES.tokens.usdt,
    name: 'Tether',
  },
  USDCe: {
    symbol: 'USDCe',
    logo: USDCLogo,
    address: ADDRESSES.tokens.usdce,
    name: 'Bridged USDC (Sonic Labs)',
  },
  scETH: {
    symbol: 'scETH',
    logo: SCETHLogo,
    address: ADDRESSES.tokens.sceth,
    name: 'Sonic ETH',
  },
  stS: {
    symbol: 'stS',
    logo: STSLogo,
    address: ADDRESSES.tokens.sts,
    name: 'Beets Staked Sonic',
  },
  WETH: {
    symbol: 'WETH',
    logo: WETHLogo,
    address: ADDRESSES.tokens.weth,
    name: 'Wrapped Ether on Sonic',
  },
  scUSD: {
    symbol: 'scUSD',
    logo: SCUSDLogo,
    address: ADDRESSES.tokens.scusd,
    name: 'Sonic USD',
  },
  SACRA: {
    symbol: 'SACRA',
    logo: SacraLogo,
    address: ADDRESSES.tokens.sacra,
    name: 'Sacra',
  },
  SACRA_GEM_1: {
    symbol: 'SACRA_GEM_1',
    logo: SacraGemLogo,
    address: ADDRESSES.tokens.sacragem,
    name: 'SacraGem',
  },
  TYSG: {
    symbol: 'TYSG',
    logo: TYSGLogo,
    address: ADDRESSES.tokens.tysg,
    name: 'Thank You Sonic God',
  },
  WOOF: {
    symbol: 'WOOF',
    logo: WOOFLogo,
    address: ADDRESSES.tokens.woof,
    name: 'Muttski',
  },
  AUR: {
    symbol: 'AUR',
    logo: AurumLogo,
    address: ADDRESSES.tokens.aurum,
    name: 'Aurum',
  },
  SolvBTC: {
    symbol: 'SolvBTC',
    logo: SOLVBTCLogo,
    address: ADDRESSES.tokens.solvbtc,
    name: 'Solv BTC',
  },
  SolvBTCBBN: {
    symbol: 'SolvBTCBBN',
    logo: SOLVBTCBBNLogo,
    address: ADDRESSES.tokens.solvbtcbbn,
    name: 'SolvBTC Babylon',
  },
  sUSDa: {
    symbol: 'sUSDa',
    logo: USDALogo,
    address: ADDRESSES.tokens.susda,
    name: 'USDa Saving Token',
  },
  USDa: {
    symbol: 'USDa',
    logo: SUSDALogo,
    address: ADDRESSES.tokens.usda,
    name: 'USDa',
  },
  atEth: {
    symbol: 'atEth',
    logo: AtEthLogo,
    address: ADDRESSES.tokens.ateth,
    name: 'Atoll ETH',
  },
  ATOLL: {
    symbol: 'ATOLL',
    logo: AtollLogo,
    address: ADDRESSES.tokens.atoll,
    name: 'Atoll Governance Token',
  },
  stkscUSD: {
    symbol: 'stkscUSD',
    logo: stkscusdLogo,
    address: ADDRESSES.tokens.stkscusd,
    name: 'Staked Sonic USD',
  },
  SHIBA: {
    symbol: 'SHIBA',
    logo: shibaLogo,
    address: ADDRESSES.tokens.shiba,
    name: 'Shiba Sonic',
  },
}
