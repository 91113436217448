import React, { useContext, useMemo, useState } from 'react'
import { useColorMode, defineStyle } from '@chakra-ui/react'
import AnalytcisHeader from '../../components/pages/analytics/analyticsHeader'
// import { useAnalyticsVersion } from '../../hooks/useGeneral'
import { useAllPairData } from '../../context/PairData'
import PairsTable from '../../components/pages/analytics/pairsTable'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import { cn } from '../../helpers/tailwind'
import SearchIcon from '../../components/icons/search'

const searchIconStyle = defineStyle({
  width: '16px',
  height: 'auto',
})

const AnalyticsPairs = () => {
  const { colorMode } = useColorMode()
  const pairs = useAllPairData()
  const assets = useContext(BaseAssetsConetext)
  const [searchText, setSearchText] = useState('')
  const pairsWithImg = useMemo(() => {
    if (Object.keys(pairs).length > 0)
      return Object.values(pairs).map((pair) => {
        const found0 = assets.find((ele) => ele.address.toLowerCase() === pair.token0.id)
        const found1 = assets.find((ele) => ele.address.toLowerCase() === pair.token1.id)
        return {
          ...pair,
          token0: {
            ...pair.token0,
            name: found0 ? found0.name : pair.token0.name,
            symbol: found0 ? found0.symbol : pair.token0.symbol,
            logo: found0 ? found0.logo : '/images/tokens/UKNOWN.png',
          },
          token1: {
            ...pair.token1,
            name: found1 ? found1.name : pair.token1.name,
            symbol: found1 ? found1.symbol : pair.token1.symbol,
            logo: found1 ? found1.logo : '/images/tokens/UKNOWN.png',
          },
        }
      })
    return []
  }, [pairs, assets])

  return (
    <>
      <div className='w-full'>
        <div className='max-w-[1104px] mx-auto w-full'>
          <AnalytcisHeader />
          <div className='flex justify-between items-center mt-8'>
            <p className='text-lg leading-8 font-medium'>All Pairs</p>
            <div className='max-w-44 rounded flex items-center relative'>
              <div className={cn('pointer-events-none absolute left-3 top-[4px] ', colorMode === 'dark' ? 'text-[#444444]' : 'text-[#121212]')}>
                <SearchIcon {...searchIconStyle} />
              </div>
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search Pairs'
                className={cn(
                  'bg-inputBackground placeholder-grayText h-full w-full text-primaryText text-sm pl-10 pr-4 py-[7px] rounded-[20px] border-[1px] outline-none transition-all duration-300 ',
                  colorMode === 'dark' ? 'border-inputBackground focus:border-[#7AF182]' : 'border-primaryBorder focus:bg-[#7AF182]',
                )}
              />
            </div>
          </div>
          <PairsTable pairsData={pairsWithImg} searchText={searchText} />
        </div>
      </div>
    </>
  )
}

export default AnalyticsPairs
