import dayjs from 'dayjs'
// import * as Apollo from '@apollo/client';

import {
  PAIR_CHART,
  PAIR_TRANSACTIONS,
  PAIRS_FROM_ADDRESSES,
  SWAPX_GLOBAL_CHART,
  SWAPX_GLOBAL_DATA,
  SWAPX_TOKENS,
  TOKEN_CHART,
  TOP_POOLS,
  TOP_POOLS_BY_TOKEN,
} from '../apollo/swapx_queries'
import swapxClient from '../apollo/swapx_client'
import { getPercentChange, getSecondsOneDay } from './analyticsHelper'
import { getSwapxBlocksFromTimestamps } from './swapxUtils'
import { TXN_TYPE } from '../config/constants'

function calcWeeklyData(_value, _new, _count) {
  return _value ? (_value * (_count <= 1 ? _count : _count - 1) + Number(_new)) / _count : Number(_new)
}

function getDurationPercent(current, prev, old, key) {
  const value = Number(current?.[key] || 0) - Number(prev?.[key] || 0)
  const preValue = Number(prev?.[key] || 0) - Number(old?.[key] || 0)
  const change = getPercentChange(value, preValue)
  return [value, change]
}

export const getChartData = async () => {
  const utcEndTime = dayjs.utc()
  // const utcStartTime = utcEndTime.subtract(1, 'month')
  const utcStartTime = utcEndTime.subtract(6, 'month')
  const oldestDateToFetch = utcStartTime.startOf('minute').unix() - 1

  let data = []
  const weeklyData = []
  let skip = 0
  let allFound = false

  try {
    while (!allFound) {
      const result = await swapxClient.query({
        query: SWAPX_GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'network-only',
      })

      // skip += 1000
      skip += 100
      data = data.concat(
        result.data.dayDatas.map((item) => {
          return { ...item, dailyVolumeUSD: Number(item.volumeUSD) }
        }),
      )
      if (result.data.dayDatas.length < 100) {
        allFound = true
      }
    }

    if (data) {
      const dayIndexSet = new Set()
      const dayIndexArray = []
      const oneDay = 24 * 60 * 60

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0))
        dayIndexArray.push(data[i])
        dayData.totalLiquidityUSD = Number(dayData.tvlUSD)
      })

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? data[0].date : oldestDateToFetch
      let latestLiquidityUSD = data[0].tvlUSD
      let latestDayDats = data[0].mostLiquidTokens
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].tvlUSD
          latestDayDats = dayIndexArray[index].mostLiquidTokens
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    data.pop()
    let _start = -1
    let currentWeek = -1
    data.forEach((entry, i) => {
      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        _start++
      }
      weeklyData[_start] = weeklyData[_start] || {}
      weeklyData[_start].date = data[i].date
      weeklyData[_start].weeklyCount = (weeklyData[_start].weeklyCount ?? 0) + 1
      weeklyData[_start].weeklyLiquidityUSD = calcWeeklyData(weeklyData[_start].weeklyLiquidityUSD, data[i].tvlUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyVolumeUSD = calcWeeklyData(weeklyData[_start].weeklyVolumeUSD, data[i].dailyVolumeUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyFeesUSD = calcWeeklyData(weeklyData[_start].weeklyFeesUSD, data[i].feesUSD, weeklyData[_start].weeklyCount)
    })
  } catch (e) {
    console.log(e)
  }
  return {
    daily: data,
    weekly: weeklyData,
  }
}

export async function getGlobalData() {
  let data = {}

  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDayBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoWeekBack = utcCurrentTime.subtract(2, 'week').unix()
    const utcOneMonthBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoMonthBack = utcCurrentTime.subtract(2, 'week').unix()

    // get the blocks needed for time travel queries
    const [oneDayBlock, twoDayBlock, oneWeekBackBlock, twoWeekBackBlock, oneMonthBackBlock, twoMonthBackBlock] = await getSwapxBlocksFromTimestamps(
      [utcOneDayBack, utcTwoDayBack, utcOneWeekBack, utcTwoWeekBack, utcOneMonthBack, utcTwoMonthBack],
      500,
    )
    // console.log("oneDayBlock, twoDayBlock, oneWeekBackBlock, twoWeekBackBlock, oneMonthBackBlock, twoMonthBackBlock", oneDayBlock, twoDayBlock, oneWeekBackBlock, twoWeekBackBlock, oneMonthBackBlock, twoMonthBackBlock)
    const [dataCurrent, dataOneDay, dataTwoDay, dataOneWeek, dataTwoWeek, dataOneMonth, dataTwoMonth] = await Promise.all([
      swapxClient.query({
        query: SWAPX_GLOBAL_DATA(),
        fetchPolicy: 'network-only',
      }),
      swapxClient.query({
        query: SWAPX_GLOBAL_DATA(oneDayBlock?.number),
        fetchPolicy: 'network-only',
      }),
      swapxClient.query({
        query: SWAPX_GLOBAL_DATA(twoDayBlock?.number),
        fetchPolicy: 'network-only',
      }),
      swapxClient.query({
        query: SWAPX_GLOBAL_DATA(oneWeekBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
      swapxClient.query({
        query: SWAPX_GLOBAL_DATA(twoWeekBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
      swapxClient.query({
        query: SWAPX_GLOBAL_DATA(oneMonthBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
      swapxClient.query({
        query: SWAPX_GLOBAL_DATA(twoMonthBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
    ])

    const [statsCurrent, statsOneDay, statsTwoDay, statsOneWeek, statsTwoWeek, statsOneMonth, statsTwoMonth] = [
      dataCurrent && dataCurrent.data && dataCurrent.data.poolFactories && dataCurrent.data.poolFactories.length > 0
        ? dataCurrent.data.poolFactories[0]
        : undefined,
      dataOneDay && dataOneDay.data && dataOneDay.data.poolFactories && dataOneDay.data.poolFactories.length > 0 ? dataOneDay.data.poolFactories[0] : undefined,
      dataTwoDay && dataTwoDay.data && dataTwoDay.data.poolFactories && dataTwoDay.data.poolFactories.length > 0 ? dataTwoDay.data.poolFactories[0] : undefined,
      dataOneWeek && dataOneWeek.data && dataOneWeek.data.poolFactories && dataOneWeek.data.poolFactories.length > 0
        ? dataOneWeek.data.poolFactories[0]
        : undefined,
      dataTwoWeek && dataTwoWeek.data && dataTwoWeek.data.poolFactories && dataTwoWeek.data.poolFactories.length > 0
        ? dataTwoWeek.data.poolFactories[0]
        : undefined,
      dataOneMonth && dataOneMonth.data && dataOneMonth.data.poolFactories && dataOneMonth.data.poolFactories.length > 0
        ? dataOneMonth.data.poolFactories[0]
        : undefined,
      dataTwoMonth && dataTwoMonth.data && dataTwoMonth.data.poolFactories && dataTwoMonth.data.poolFactories.length > 0
        ? dataTwoMonth.data.poolFactories[0]
        : undefined,
    ]

    const liquidityOneDayChangeUSD = getPercentChange(statsCurrent ? statsCurrent.totalValueLockedUSD : 0, statsOneDay ? statsOneDay.totalValueLockedUSD : 0)
    const liquidityOneWeekChangeUSD = getPercentChange(statsCurrent ? statsCurrent.totalValueLockedUSD : 0, statsOneDay ? statsOneWeek.totalValueLockedUSD : 0)
    const liquidityOneMonthChangeUSD = getPercentChange(
      statsCurrent ? statsCurrent.totalValueLockedUSD : 0,
      statsOneDay ? statsOneMonth.totalValueLockedUSD : 0,
    )

    const [volumeOneDayUSD, volumeOneDayChange] = getDurationPercent(statsCurrent, statsOneDay, statsTwoDay, 'totalVolumeUSD')
    const [volumeOneWeekUSD, volumeOneWeekChange] = getDurationPercent(statsCurrent, statsOneWeek, statsTwoWeek, 'totalVolumeUSD')
    const [volumeOneMonthUSD, volumeOneMonthChange] = getDurationPercent(statsCurrent, statsOneMonth, statsTwoMonth, 'totalVolumeUSD')

    const [feesOneDayUSD, feesOneDayChange] = getDurationPercent(statsCurrent, statsOneDay, statsTwoDay, 'totalFeesUSD')
    const [feesOneWeekUSD, feesOneWeekChange] = getDurationPercent(statsCurrent, statsOneWeek, statsTwoWeek, 'totalFeesUSD')
    const [feesOneMonthUSD, feesOneMonthChange] = getDurationPercent(statsCurrent, statsOneMonth, statsTwoMonth, 'totalFeesUSD')

    data = {
      liquidityOneDayUSD: Number(statsCurrent.totalValueLockedUSD),
      liquidityOneWeekUSD: Number(statsOneWeek.totalValueLockedUSD),
      liquidityOneMonthUSD: Number(statsOneMonth.totalValueLockedUSD),
      liquidityOneDayChangeUSD,
      liquidityOneWeekChangeUSD,
      liquidityOneMonthChangeUSD,
      totalVolumeUSD: Number(statsCurrent.totalVolumeUSD),
      volumeOneDayUSD,
      volumeOneDayChange,
      volumeOneWeekUSD,
      volumeOneWeekChange,
      volumeOneMonthUSD,
      volumeOneMonthChange,
      feesOneDayUSD,
      feesOneDayChange,
      feesOneWeekUSD,
      feesOneWeekChange,
      feesOneMonthUSD,
      feesOneMonthChange,
    }
  } catch (e) {
    console.log(e)
  }

  return data
}

async function fetchTokensByTime(date) {
  try {
    const tokens = await swapxClient.query({
      query: SWAPX_TOKENS(date),
      fetchPolicy: 'network-only',
    })

    return tokens.data.tokens
  } catch (err) {
    console.error('Tokens fetching by time in v3 ' + err)
    return
  }
}

function parseTokensData(tokenData, isCurrent) {
  return tokenData
    ? tokenData.reduce((acc, tokenData) => {
        if (isCurrent) {
          acc[tokenData.id] = {
            ...(tokenData.tokenDayData?.[0] || {}),
            id: tokenData?.id,
            name: tokenData?.name,
            symbol: tokenData?.symbol,
            decimals: tokenData?.decimals,
            volumeUSD: tokenData?.volumeUSD,
            feesUSD: tokenData?.feesUSD,
            txCount: tokenData?.txCount,
            totalValueLockedUSD: tokenData?.totalValueLockedUSD,
          }
        } else {
          acc[tokenData.id] = {
            id: tokenData?.id,
            name: tokenData?.name,
            symbol: tokenData?.symbol,
            decimals: tokenData?.decimals,
            volumeUSD: tokenData?.volumeUSD,
            feesUSD: tokenData?.feesUSD,
            txCount: tokenData?.txCount,
            totalValueLockedUSD: tokenData?.totalValueLockedUSD,
            ...(tokenData.tokenDayData?.[0] || {}),
          }
        }
        return acc
      }, {})
    : {}
}

const WETH_ADDRESSES = ['0x2c1b868d6596a18e32e61b901e4060c872647b6c']

export function formatTokenSymbol(address, symbol) {
  if (WETH_ADDRESSES.includes(address)) {
    return 'ETH'
  } else if (symbol.toLowerCase() === 'mimatic') {
    return 'MAI'
  } else if (symbol.toLowerCase() === 'amaticc') {
    return 'ankrMATIC'
  }
  return symbol
}

export function formatTokenName(address, name) {
  if (WETH_ADDRESSES.includes(address)) {
    return 'Eth'
  }
  return name
}

export async function getTopTokens() {
  try {
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDayBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoWeekBack = utcCurrentTime.subtract(2, 'week').unix()
    const utcOneMonthBack = utcCurrentTime.subtract(1, 'month').unix()
    const utcTwoMonthBack = utcCurrentTime.subtract(2, 'month').unix()

    const [dataCurrent, dataOneDay, dataTwoDay, dataOneWeek, dataTwoWeek, dataOneMonth, dataTwoMonth] = await Promise.all([
      fetchTokensByTime(undefined),
      fetchTokensByTime(utcOneDayBack),
      fetchTokensByTime(utcTwoDayBack),
      fetchTokensByTime(utcOneWeekBack),
      fetchTokensByTime(utcTwoWeekBack),
      fetchTokensByTime(utcOneMonthBack),
      fetchTokensByTime(utcTwoMonthBack),
    ])

    const parsedCurrentTokens = parseTokensData(dataCurrent, true)
    const parsedOneDayTokens = parseTokensData(dataOneDay)
    const parsedTwoDayTokens = parseTokensData(dataTwoDay)
    const parsedOneWeekTokens = parseTokensData(dataOneWeek)
    const parsedTwoWeekTokens = parseTokensData(dataTwoWeek)
    const parsedOneMonthTokens = parseTokensData(dataOneMonth)
    const parsedTwoMonthTokens = parseTokensData(dataTwoMonth)

    const tokenAddresses = Object.keys(parsedCurrentTokens)

    const formatted = tokenAddresses.map((address) => {
      const current = parsedCurrentTokens[address]
      const oneDay = parsedOneDayTokens[address]
      const twoDay = parsedTwoDayTokens[address]
      const oneWeek = parsedOneWeekTokens[address]
      const twoWeek = parsedTwoWeekTokens[address]
      const oneMonth = parsedOneMonthTokens[address]
      const twoMonth = parsedTwoMonthTokens[address]

      const manageUntrackedVolume = current ? (+current.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD') : ''
      // const manageUntrackedTVL = current ? (+current.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD') : ''
      const manageUntrackedTVL = current ? 'totalValueLockedUSD' : ''

      const [volumeOneDayUSD, volumeOneDayChangeUSD] = getDurationPercent(current, oneDay, twoDay, manageUntrackedVolume)
      const [volumeOneWeekUSD, volumeOneWeekChangeUSD] = getDurationPercent(current, oneWeek, twoWeek, manageUntrackedVolume)
      const [volumeOneMonthUSD, volumeOneMonthChangeUSD] = getDurationPercent(current, oneMonth, twoMonth, manageUntrackedVolume)

      let oneDayTxns = Number(current && current.txCount ? current.txCount : 0) - Number(oneDay && oneDay.txCount ? oneDay.txCount : 0)
      let twoDayTxns = Number(oneDay && oneDay.txCount ? oneDay.txCount : 0) - Number(twoDay && twoDay.txCount ? twoDay.txCount : 0)
      const txnChange = getPercentChange(oneDayTxns, twoDayTxns)

      const liquidityUSD = current ? parseFloat(current[manageUntrackedTVL]) : 0
      const liquidityUSDOneDay = oneDay ? parseFloat(oneDay[manageUntrackedTVL]) : 0
      const liquidityUSDOneWeek = oneWeek ? parseFloat(oneWeek[manageUntrackedTVL]) : 0
      const liquidityUSDOneMonth = oneMonth ? parseFloat(oneMonth[manageUntrackedTVL]) : 0
      const liquidityUSDOneDayChange = getPercentChange(liquidityUSD, liquidityUSDOneDay)
      const liquidityUSDOneWeekChange = getPercentChange(liquidityUSD, liquidityUSDOneWeek)
      const liquidityUSDOneMonthChange = getPercentChange(liquidityUSD, liquidityUSDOneMonth)

      let priceUSD = current ? parseFloat(current?.priceUSD || '0') : 0
      const priceUSDOneDay = oneDay ? parseFloat(oneDay?.priceUSD || '0') : 0
      const priceUSDOneWeek = oneWeek ? parseFloat(oneWeek?.priceUSD || '0') : 0
      const priceUSDOneMonth = oneMonth ? parseFloat(oneMonth?.priceUSD || '0') : 0

      const priceOneDayChangeUSD = priceUSD > 0 && priceUSDOneDay > 0 ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneDay.toString())) : 0
      const priceOneWeekChangeUSD = priceUSD > 0 && priceUSDOneWeek > 0 ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneWeek.toString())) : 0
      const priceOneMonthChangeUSD =
        priceUSD > 0 && priceUSDOneMonth > 0 ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneMonth.toString())) : 0

      if (priceUSD < 0.000001) {
        priceUSD = 0
      }
      return {
        id: address,
        name: formatTokenName(address, current && current.name ? current.name : ''),
        symbol: formatTokenSymbol(address, current && current.symbol ? current.symbol : ''),
        decimals: current && current.decimals ? current.decimals : 18,
        volumeOneDayUSD: volumeOneDayUSD < 0.0001 ? 0 : volumeOneDayUSD,
        volumeOneWeekUSD,
        volumeOneMonthUSD,
        volumeOneDayChangeUSD,
        volumeOneWeekChangeUSD,
        volumeOneMonthChangeUSD,
        oneDayTxns,
        twoDayTxns,
        txnChange,
        liquidityUSD,
        liquidityUSDOneDay: liquidityUSD,
        liquidityUSDOneWeek,
        liquidityUSDOneMonth,
        liquidityUSDOneDayChange,
        liquidityUSDOneWeekChange,
        liquidityUSDOneMonthChange,
        priceUSD,
        priceOneDayUSD: priceUSD,
        priceOneWeekUSD: Number(priceUSDOneWeek.toString()),
        priceOneMonthUSD: Number(priceUSDOneMonth.toString()),
        priceOneDayChangeUSD,
        priceOneWeekChangeUSD,
        priceOneMonthChangeUSD,
      }
    })

    return formatted
      .sort((a, b) => {
        return b.liquidityUSD - a.liquidityUSD
      })
      .reduce((acc, token) => {
        return { ...acc, [token.id]: token }
      }, {})
  } catch (err) {
    console.log('get swapx top tokens :>> ', err)
  }
}

export const getSwapxTokenChartData = async (tokenAddress) => {
  let data = []
  const weeklyData = []
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(3, 'month')
  const startTime = utcStartTime.startOf('minute').unix() - 1
  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      const result = await swapxClient.query({
        query: TOKEN_CHART,
        variables: {
          startTime: startTime,
          tokenAddr: tokenAddress.toLowerCase(),
          skip,
        },
        fetchPolicy: 'network-only',
      })

      if (result.data.tokens?.[0].tokenDayData.length < 100) {
        allFound = true
      }
      skip += 100
      data = data.concat(result.data.tokens?.[0].tokenDayData)
    }

    const dayIndexSet = new Set()
    const dayIndexArray = []
    const oneDay = getSecondsOneDay()

    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.dailyVolumeUSD = Number(dayData.volumeUSD)
      dayData.totalLiquidityUSD = Number(dayData.totalValueLockedUSD)
    })

    // fill in empty days
    let timestamp = data[0] && data[0].date ? data[0].date : startTime
    let latestLiquidityUSD = data[0] && data[0].totalValueLockedUSD
    let latestPriceUSD = data[0] && data[0].priceUSD
    let index = 1
    while (timestamp < utcEndTime.startOf('minute').unix() - oneDay) {
      const nextDay = timestamp + oneDay
      const currentDayIndex = (nextDay / oneDay).toFixed(0)
      if (!dayIndexSet.has(currentDayIndex)) {
        data.push({
          date: nextDay,
          dayString: nextDay,
          dailyVolumeUSD: 0,
          priceUSD: latestPriceUSD,
          totalLiquidityUSD: latestLiquidityUSD,
        })
      } else {
        latestLiquidityUSD = dayIndexArray[index].totalValueLockedUSD
        latestPriceUSD = dayIndexArray[index].priceUSD
        index = index + 1
      }
      timestamp = nextDay
    }
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))

    data.pop()
    let _start = -1
    let currentWeek = -1
    data.forEach((entry, i) => {
      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        _start++
      }
      weeklyData[_start] = weeklyData[_start] || {}
      weeklyData[_start].date = data[i].date
      weeklyData[_start].weeklyCount = (weeklyData[_start].weeklyCount ?? 0) + 1
      // weeklyData[_start].weeklyLiquidityUSD = (weeklyData[_start].weeklyLiquidityUSD ?? 0) + Number(data[i].totalLiquidityUSD)
      weeklyData[_start].weeklyLiquidityUSD = calcWeeklyData(weeklyData[_start].weeklyLiquidityUSD, data[i].totalLiquidityUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyVolumeUSD = calcWeeklyData(weeklyData[_start].weeklyVolumeUSD, data[i].dailyVolumeUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyPriceUSD = calcWeeklyData(weeklyData[_start].weeklyPriceUSD, data[i].priceUSD, weeklyData[_start].weeklyCount)
    })
  } catch (e) {
    console.log(e)
  }
  return {
    daily: data,
    weekly: weeklyData,
  }
}

export const getSwapxTokenPairs = async (tokenAddress) => {
  try {
    const result = await swapxClient.query({
      query: TOP_POOLS_BY_TOKEN,
      variables: {
        address: tokenAddress,
      },
      fetchPolicy: 'network-only',
    })
    const pools_1 = result.data?.['pools'].map((pair) => pair.id)
    return pools_1
  } catch (e) {
    console.log(e)
    return
  }
}

async function fetchPairsByTime(blockNumber, tokenAddresses) {
  try {
    const pairs = await swapxClient.query({
      query: PAIRS_FROM_ADDRESSES(blockNumber, tokenAddresses),
      fetchPolicy: 'network-only',
    })

    return pairs.data.pools
  } catch (err) {
    console.error('Pairs by time fetching ' + err)
    return
  }
}

function parsePairsData(pairData) {
  return pairData
    ? pairData.reduce((accum, poolData) => {
        accum[poolData.id] = poolData
        return accum
      }, {})
    : {}
}

export async function getSwapxTopPairs(count) {
  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcOneMonthBack = utcCurrentTime.subtract(1, 'month').unix()

    const [oneDayBlock, oneWeekBlock, oneMonthBlock] = await getSwapxBlocksFromTimestamps([utcOneDayBack, utcOneWeekBack, utcOneMonthBack], 500)

    const pairsIds = await swapxClient.query({
      query: TOP_POOLS(count),
      fetchPolicy: 'network-only',
    })

    const pairsAddresses = pairsIds.data.pools.map((el) => el.id)
    const [pairsCurrent, pairs24, pairsWeek, pairsMonth] = await Promise.all([
      fetchPairsByTime(undefined, pairsAddresses),
      fetchPairsByTime(oneDayBlock.number, pairsAddresses),
      fetchPairsByTime(oneWeekBlock.number, pairsAddresses),
      fetchPairsByTime(oneMonthBlock.number, pairsAddresses),
    ])

    const parsedPairs = parsePairsData(pairsCurrent)
    const parsedPairs24 = parsePairsData(pairs24)
    const parsedPairsWeek = parsePairsData(pairsWeek)
    const parsedPairsMonth = parsePairsData(pairsMonth)

    const formatted = pairsAddresses.map((address) => {
      const current = parsedPairs[address]
      const oneDay = parsedPairs24[address]
      const week = parsedPairsWeek[address]
      const month = parsedPairsMonth[address]

      const manageUntrackedVolume = current && current.volumeUSD && Number(current.volumeUSD) <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD'

      const manageUntrackedTVL =
        current && current.totalValueLockedUSD && Number(current.totalValueLockedUSD) <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD'

      const v3CurrentVolumeUSD = current && current[manageUntrackedVolume] ? Number(current[manageUntrackedVolume]) : 0
      const v3OneDayVolumeUSD = oneDay && oneDay[manageUntrackedVolume] ? Number(oneDay[manageUntrackedVolume]) : 0
      const v3WeekVolumeUSD = week && week[manageUntrackedVolume] ? Number(week[manageUntrackedVolume]) : 0
      const v3MonthVolumeUSD = month && month[manageUntrackedVolume] ? Number(month[manageUntrackedVolume]) : 0
      const oneDayVolumeUSD = v3CurrentVolumeUSD - v3OneDayVolumeUSD
      const oneWeekVolumeUSD = v3CurrentVolumeUSD - v3WeekVolumeUSD
      const oneMonthVolumeUSD = v3CurrentVolumeUSD - v3MonthVolumeUSD

      const currentFees = current && current['feesUSD'] ? Number(current['feesUSD']) : 0
      const oneDayFees = oneDay && oneDay['feesUSD'] ? Number(oneDay['feesUSD']) : 0
      const oneWeekFees = week && week['feesUSD'] ? Number(week['feesUSD']) : 0
      const oneMonthFees = month && month['feesUSD'] ? Number(month['feesUSD']) : 0
      const oneDayFeesUSD = currentFees - oneDayFees
      const oneWeekFeesUSD = currentFees - oneWeekFees
      const oneMonthFeesUSD = currentFees - oneMonthFees

      const v3CurrentTVL = current && current[manageUntrackedTVL] ? Number(current[manageUntrackedTVL]) : 0
      const v3OneDayTVL = oneDay && oneDay[manageUntrackedTVL] ? Number(oneDay[manageUntrackedTVL]) : 0
      const v3OneWeekTVL = week && week[manageUntrackedTVL] ? Number(week[manageUntrackedTVL]) : 0
      const v3OneMonthTVL = week && week[manageUntrackedTVL] ? Number(week[manageUntrackedTVL]) : 0
      const tvlUSD = v3CurrentTVL
      const tvlOneWeekUSD = v3OneWeekTVL
      const tvlOneMonthUSD = v3OneMonthTVL
      const tvlUSDChange = getPercentChange(tvlUSD, v3OneDayTVL)

      return current
        ? {
            token0: current.token0,
            token1: current.token1,
            reserve0: current.totalValueLockedToken0,
            reserve1: current.totalValueLockedToken1,
            fee: current.fee,
            id: address,
            oneDayVolumeUSD,
            oneWeekVolumeUSD,
            oneMonthVolumeUSD,
            oneDayFeesUSD,
            oneWeekFeesUSD,
            oneMonthFeesUSD,
            trackedReserveUSD: tvlUSD,
            tvlUSDChange,
            totalValueLockedUSD: tvlUSD,
            tvlOneWeekUSD,
            tvlOneMonthUSD,
          }
        : undefined
    })

    return formatted
  } catch (err) {
    console.log(err)
  }
}

export async function getSwapxPairTransactions(address) {
  let newTxns = []
  const data = await swapxClient.query({
    query: PAIR_TRANSACTIONS,
    variables: {
      address: address,
    },
    fetchPolicy: 'cache-first',
  })

  data.data.mints.forEach((mint) => {
    let newTxn = {}
    newTxn.hash = mint.transaction.id
    newTxn.timestamp = mint.timestamp
    newTxn.type = TXN_TYPE.ADD
    newTxn.token0Amount = mint.amount0
    newTxn.token1Amount = mint.amount1
    newTxn.account = mint.origin
    newTxn.token0Symbol = formatTokenSymbol(mint.pool.token0.id, mint.pool.token0.symbol)
    newTxn.token1Symbol = formatTokenSymbol(mint.pool.token1.id, mint.pool.token1.symbol)
    newTxn.amountUSD = mint.amountUSD
    newTxns.push(newTxn)
  })
  data.data.burns.forEach((burn) => {
    let newTxn = {}
    newTxn.hash = burn.transaction.id
    newTxn.timestamp = burn.timestamp
    newTxn.type = TXN_TYPE.REMOVE
    newTxn.token0Amount = burn.amount0
    newTxn.token1Amount = burn.amount1
    newTxn.account = burn.owner
    newTxn.token0Symbol = formatTokenSymbol(burn.pool.token0.id, burn.pool.token0.symbol)
    newTxn.token1Symbol = formatTokenSymbol(burn.pool.token1.id, burn.pool.token1.symbol)
    newTxn.amountUSD = burn.amountUSD
    newTxns.push(newTxn)
  })

  data.data.swaps.forEach((swap) => {
    let newTxn = {}
    newTxn.hash = swap.transaction.id
    newTxn.timestamp = swap.timestamp
    newTxn.type = TXN_TYPE.SWAP
    newTxn.account = swap.origin

    if (parseFloat(swap.amount0) < 0) {
      newTxn.token0Amount = Math.abs(swap.amount0)
      newTxn.token1Amount = Math.abs(swap.amount1)
      newTxn.token0Symbol = formatTokenSymbol(swap.pool.token0.id, swap.pool.token0.symbol)
      newTxn.token1Symbol = formatTokenSymbol(swap.pool.token1.id, swap.pool.token1.symbol)
    } else {
      newTxn.token1Amount = Math.abs(swap.amount0)
      newTxn.token0Amount = Math.abs(swap.amount1)
      newTxn.token1Symbol = formatTokenSymbol(swap.pool.token0.id, swap.pool.token0.symbol)
      newTxn.token0Symbol = formatTokenSymbol(swap.pool.token1.id, swap.pool.token1.symbol)
    }
    newTxn.amountUSD = swap.amountUSD
    newTxns.push(newTxn)
  })

  return newTxns
}

export const getSwapxPairChartData = async (pairAddress) => {
  let data = []
  const weeklyData = []
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(6, 'month').startOf('minute')
  const startTime = utcStartTime.unix() - 1
  let allFound = false
  let skip = 0
  try {
    while (!allFound) {
      const result = await swapxClient.query({
        query: PAIR_CHART,
        variables: {
          startTime: startTime,
          pairAddress: pairAddress,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      data = data.concat(result.data.poolDayDatas)
      if (result.data.poolDayDatas.length < 1000) {
        allFound = true
      }
    }

    const dayIndexSet = new Set()
    const dayIndexArray = []
    const oneDay = 24 * 60 * 60
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.dailyVolumeUSD = Number(dayData.volumeUSD)
      dayData.reserveUSD = Number(dayData.tvlUSD)
      dayData.feesUSD = Number(dayData.feesUSD)
      // dayData.token0Price = dayData.token0Price;
      // dayData.token1Price = dayData.token1Price;
    })

    if (data[0]) {
      // fill in empty days
      let timestamp = data[0].date ? data[0].date : startTime
      let latestLiquidityUSD = data[0].tvlUSD
      let latestFeesUSD = data[0].feesUSD
      let latestToken0Price = data[0].token0Price
      let latestToken1Price = data[0].token1Price
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dayString: nextDay,
            dailyVolumeUSD: 0,
            reserveUSD: latestLiquidityUSD,
            feesUSD: latestFeesUSD,
            token0Price: latestToken0Price,
            token1Price: latestToken1Price,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].tvlUSD
          latestFeesUSD = dayIndexArray[index].feesUSD
          latestToken0Price = dayIndexArray[index].token0Price
          latestToken1Price = dayIndexArray[index].token1Price
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    data.pop()
    let _start = -1
    let currentWeek = -1
    data.forEach((entry, i) => {
      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        _start++
      }
      weeklyData[_start] = weeklyData[_start] || {}
      weeklyData[_start].date = data[i].date
      weeklyData[_start].weeklyCount = (weeklyData[_start].weeklyCount ?? 0) + 1
      weeklyData[_start].weeklyTvlUSD = calcWeeklyData(weeklyData[_start].weeklyTvlUSD, data[i].reserveUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyVolumeUSD = calcWeeklyData(weeklyData[_start].weeklyVolumeUSD, data[i].dailyVolumeUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyFeesUSD = calcWeeklyData(weeklyData[_start].weeklyFeesUSD, data[i].feesUSD, weeklyData[_start].weeklyCount)
    })
  } catch (e) {
    console.log(e)
  }
  return {
    daily: data,
    weekly: weeklyData,
  }
}
