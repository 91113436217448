import { Route, Routes } from 'react-router-dom'
import { useColorMode } from '@chakra-ui/react'
import Layout from './components/common/Layout'
import PageNotFound from './pages/404NotFound'
import { RefreshContextProvider } from './context/RefreshContext'
import { ToastContainer, Zoom } from 'react-toastify'
import { useVideoAutoplay } from './helpers/useAutoPlay'
import { VeTHEsContextProvider } from './context/veTHEsConetext'
import { BaseAssetsConetextProvider } from './context/BaseAssetsConetext'
import { FusionsContextProvider } from './context/FusionsContext'
import { PoolsContextProvider } from './context/PoolsContext'
import ApplicationUpdater from './state/application/updater'
import MultiCallV3Updater from './state/multicall/v3/updater'
import AnalyticsOverview from './pages/analytics'
import AnalyticsTokens from './pages/analytics/tokens'
import AnalyticsPairs from './pages/analytics/pairs'
import TokenDetails from './pages/analytics/token/index'
import PairDetails from './pages/analytics/pair/index'
import TokenDataContextProvider from './context/TokenData'
import GlobalDataContextProvider from './context/GlobalData'
import PairDataContextProvider from './context/PairData'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { chains, wagmiConfig } from './wagmi.config'
import { WagmiConfig } from 'wagmi'
import { RainbowKitProvider, midnightTheme } from '@rainbow-me/rainbowkit'
import { Web3ContextProvider } from './context/Web3Conetext'
// import { GlobalContextProvider } from './context/GlobalContext'

const ContextProviders = ({ children }) => {
  return (
    <Web3ContextProvider>
      <BaseAssetsConetextProvider>
        <VeTHEsContextProvider>
          <FusionsContextProvider>
            <PoolsContextProvider>
              <TokenDataContextProvider>
                <GlobalDataContextProvider>
                  <PairDataContextProvider>
                    {/* <GlobalContextProvider> */}
                    {children}
                    {/* </GlobalContextProvider> */}
                  </PairDataContextProvider>
                </GlobalDataContextProvider>
              </TokenDataContextProvider>
            </PoolsContextProvider>
          </FusionsContextProvider>
        </VeTHEsContextProvider>
      </BaseAssetsConetextProvider>
    </Web3ContextProvider>
  )
}

const Updaters = () => {
  return (
    <>
      <ApplicationUpdater />
      <MultiCallV3Updater />
    </>
  )
}

const App = () => {
  useVideoAutoplay()
  const { colorMode } = useColorMode()

  return (
    <div className={`main ${colorMode === 'dark' ? 'dark-theme' : ''}`}>
      <RefreshContextProvider>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider
            chains={chains}
            theme={midnightTheme({
              accentColor: '#DF832F',
            })}
          >
            <ContextProviders>
              {' '}
              <Updaters />
              <Routes>
                <Route element={<Layout />}>
                  <Route path='/' element={<AnalyticsOverview />} exact />
                  <Route path='/analytics?' element={<AnalyticsOverview />} exact />
                  <Route path='/analytics/pairs' element={<AnalyticsPairs />} exact />
                  <Route path='/analytics/tokens' element={<AnalyticsTokens />} exact />
                  <Route path='/analytics/token/:id' element={<TokenDetails />} exact />
                  <Route path='/analytics/pair/:id' element={<PairDetails />} exact />
                  <Route path='/404' element={<PageNotFound />} exact />
                  <Route path='*' element={<PageNotFound />} exact />
                </Route>
              </Routes>
            </ContextProviders>
          </RainbowKitProvider>
        </WagmiConfig>
      </RefreshContextProvider>
      <ToastContainer
        className='notify-class'
        position='top-right'
        theme='dark'
        closeOnClick={false}
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={true}
        closeButton={false}
      />
    </div>
  )
}

export default App
